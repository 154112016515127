"use strict";

module.exports = function () {
    return {
        restrict: "E",
        scope: {
            availableRooms: "=",
            selectedRoom: "=",
            selectedBedId: "=",
            loadingRoomsAndBeds: "=",
            internalDisabled: "<"
        },
        controller: "residentRoomAssignment.controller",
        controllerAs: "vm",
        replace: false,
        templateUrl: "app/common/directives/partials/residentRoomAssignment.html"
    };
}